import { motion } from 'framer-motion'
import Logo from "../../assets/image/logo.png"
import SDButton from "../../assets/image/sdbutton.png"
import DSButton from "../../assets/image/dsbutton.png"
import ArtButton from "../../assets/image/artbutton.png"

interface NavbarProps {
    onPageChange: (pageName: string) => void;
}

export const Navbar: React.FC<NavbarProps> = ({ onPageChange }) => {
   
    return (
        <div className="grid lg:gap-2 align-bottom lg:align-center items-center justify-center grid-cols-1  lg:grid-cols-4 px-10 pb-0 pointer-events-none">

                <motion.img initial={{ x: -100, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.5, ease: "easeOut" }}
                 className="object-contain min-h-[32px] min-w-[300px] w-full lg:w-96 lg:h-48 lg:min-w-[0px] pointer-events-none" src={Logo} alt="Logo" />
                <div onClick={() => onPageChange('home')}>
                <motion.img initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                transition={{ duration: 0.5, ease: "backOut" }}
                whileHover={{
                    scale: 1.1,
                    rotate: 5,
                    transition: { duration: 0.3 },
                }}
                className="object-contain hidden lg:inline lg:w-96 lg:h-48 lg:min-w-[0px] pointer-events-auto" src={DSButton} alt="DS Button"/>
                </div>
                <div onClick={() => onPageChange('software')}>
                <motion.img initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                transition={{ duration: 0.5, ease: "backOut" }}
                whileHover={{
                    scale: 1.1,
                    rotate: -3.5,
                    transition: { duration: 0.3 },
                }}
                className="object-contain hidden lg:inline lg:w-96 lg:h-48 lg:min-w-[0px] pointer-events-auto" src={SDButton} alt="SD Button"/>
                    </div>
                <div onClick={() => onPageChange('art')}>
                <motion.img initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                transition={{ duration: 0.5, ease: "backOut" }}
                whileHover={{
                    scale: 1.1,
                    rotate: -3.5,
                    transition: { duration: 0.3 },
                }}
                className="object-contain hidden lg:inline lg:w-96 lg:h-48 lg:min-w-[0px] pointer-events-auto" src={ArtButton} alt="Art Button"/>
                </div>
                <div className="grid lg:hidden gap-2 items-center grid-cols-3">
                    <div onClick={() => onPageChange('home')}>
                    <motion.img initial={{ scale: 0 }}
                    animate={{ scale: 1 }}
                    transition={{ duration: 0.5, ease: "backOut" }}
                    whileHover={{
                        scale: 1.1,
                        rotate: 5,
                        transition: { duration: 0.3 },
                    }}
                    className="object-contain w-52 h-32 pointer-events-auto" src={DSButton} alt="DS Button"/>
                    </div>
                    <div onClick={() => onPageChange('software')}>
                    <motion.img initial={{ scale: 0 }}
                    animate={{ scale: 1 }}
                    transition={{ duration: 0.5, ease: "backOut" }}
                    whileHover={{
                        scale: 1.1,
                        rotate: -3.5,
                        transition: { duration: 0.3 },
                    }}
                    className="object-contain w-52 h-32 pointer-events-auto" src={SDButton} alt="SD Button"/>
                        </div>
                    <div onClick={() => onPageChange('art')}>
                    <motion.img initial={{ scale: 0 }}
                    animate={{ scale: 1 }}
                    transition={{ duration: 0.5, ease: "backOut" }}
                    whileHover={{
                        scale: 1.1,
                        rotate: -3.5,
                        transition: { duration: 0.3 },
                    }}
                    className="object-contain w-52 h-32 pointer-events-auto" src={ArtButton} alt="Art Button"/>
                    </div>
                </div>
        </div>
    )
}