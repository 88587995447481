import {Card, CardHeader, CardBody, CardFooter, Divider, Link, Image} from "@nextui-org/react";
import { motion } from "framer-motion";
import { ReactNode } from "react";

interface CardProjectProps {
    logo: string;
    title: string;
    website: string;
    skills: string;
    hyperlink: string;
    hyperlinkText: string;
    children?: ReactNode;
}

export const ProjectCard: React.FC<CardProjectProps>  = ({logo, title, website, skills, hyperlink, hyperlinkText, children}) => {
   return (
    <motion.div
    whileHover={{
        scale: 1.01,
        boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.8)",
        backgroundColor: "#7f1d1d", // Tailwind's indigo-500
      }}
    >
        <Card
        isBlurred
        style={{
            backgroundColor: 'rgba(255, 255, 255, 0.6)', // Light theme
            border: 'none',
            maxWidth: '100%',
            boxShadow: 'var(--nextui-shadows-sm)', // Using Next UI's shadow variable
        }}
        shadow="sm"
        >
        <CardHeader className="flex gap-3">
            <Image
            alt="lutece logo"
            height={40}
            radius="sm"
            src={logo}
            width={40}
            />
            <div className="flex flex-col">
            <p className="text-xl font-sans"><b>{title}</b></p>
            <Link
            isExternal
            showAnchorIcon
            href={website}
            className="text-small text-default-500 font-sans">{website}</Link>
            </div>
        </CardHeader>
        <Divider/>
        <CardBody>
            {children}
            <p>~</p>
            <p className='font-sans'>
            <b>Skills:</b> {skills}
            </p>
        </CardBody>
        <Divider/>
        <CardFooter>
            
           {(hyperlink !== "" && <Link
            isExternal
            showAnchorIcon
            href={hyperlink}
            > <p className='font-sans'> {hyperlinkText} </p>
            </Link>)}
        </CardFooter>
        </Card>
    </motion.div>
   )
}

//"https://github.com/lutece-platform/lutece-tech-library-jwt"