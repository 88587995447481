import React from 'react'

interface LoadingProps {
size:number
}

export const Loading: React.FC<LoadingProps> = ({size}) => {

    return (
        <div className="w-full h-full flex justify-center items-center">
            <div
            style={{ width: `${size}px`, height: `${size}px` }}
            className="animate-spin flex justify-center align-middle items-center">
            <div className="h-full w-full border-4 border-t-red-500
                 border-b-red-700 rounded-[50%]">
            </div>
            </div>
        </div>
    );
}


