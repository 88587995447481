import HeroImage from "../../assets/image/StickerLowDef.png"
import GithubLogo from "../../assets/image/github.png"
import InstagramLogo from "../../assets/image/instagram.png"
import LinkedinLogo from "../../assets/image/linkedin.png"
import GmailLogo from "../../assets/image/gmail.png"
import { motion } from 'framer-motion'
import Text from "../../assets/image/tex.png"

export const Homepage = () => {
    const emailLink = "mailto:elmimoallin@gmail.com";
    const linkedInLink = "https://www.linkedin.com/in/elmi-moallin-b6682419a/";
    const githubLink = "https://www.github.com/Nephrot";
    const instagramLink = "https://www.instagram.com/bl.ack.j.ack/";
    
    return (
        <div className="grid grid-cols-1 gap-2 px-4 pb-0 lg:grid-cols-2 lg:px-4 lg:pb-0 pointer-events-none">
        <div className="pointer-events-none align-middle  mx-auto ">
            <motion.img initial={{ x: -100, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.5, ease: "easeOut" }}
            className="mix-blend-difference 2xl:px-10 px-15 pb-3 object-fill align-middle pointer-events-none mx-auto flex lg:h-[25rem] 3xl:h-[40rem] w-full" src={Text} alt="Text"/>
            <div className="items-center justify-center text-center grid gap-2 grid-cols-4 pl-[8%] pr-[10%] md:pl-[11%] lg:px-10 pb-10 lg:pb-0 pointer-events-auto">
                <a href={emailLink}>
                <motion.img initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                transition={{ duration: 0.5, ease: "backOut" }} 
                whileHover={{
                    scale: 1.1,
                    rotate: -5,
                    boxShadow: "0 0 17px rgba(128, 9, 27, 0.8)",
                    transition: { duration: 0.3 },
                }}
                
                className="object-contain w-28 h-28" src={GmailLogo} alt="Gmail Link"/>
                </a>
                <a href={linkedInLink}>
                    <motion.img initial={{ scale: 0 }}
                    animate={{ scale: 1 }}
                    transition={{ duration: 0.5, ease: "backOut" }} 
                    whileHover={{
                        scale: 1.1,
                        rotate: -5,
                        boxShadow: "0 0 17px rgba(72, 138, 199, 0.8)",
                        transition: { duration: 0.3 },
                    }}
                    className="object-contain w-28 h-28" src={LinkedinLogo} alt="Linkedin Link"/>
                </a>
                <a href={githubLink}>
                    <motion.img initial={{ scale: 0 }}
                    animate={{ scale: 1 }}
                    transition={{ duration: 0.5, ease: "backOut" }} 
                    whileHover={{
                        scale: 1.1,
                        rotate: -5,
                        boxShadow: "0 0 17px rgba(76, 187, 23, 0.8)",
                        transition: { duration: 0.3 },
                    }}
                    className="object-contain w-28 h-28" src={GithubLogo} alt="Github Link"/>
                </a>
                <a href={instagramLink}>
                    <motion.img initial={{ scale: 0 }}
                    animate={{ scale: 1 }}
                    transition={{ duration: 0.5, ease: "backOut" }} 
                    whileHover={{
                        scale: 1.1,
                        rotate: -5,
                        boxShadow: "0 0 17px rgba(148, 62, 143, 0.8)",
                        transition: { duration: 0.3 },
                    }}
                    className="object-contain w-28 h-28" src={InstagramLogo} alt="Instagram Link"/>
                </a>
            </div>
        </div>
        <div className="hidden lg:flex lg:flex-col items-center w-auto pointer-events-none"> 
            <img className="align-top pointer-events-none h-[35rem] w-auto" src={HeroImage} alt="Hero"/>
        </div>
        </div>
    )
}