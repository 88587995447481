import Logo from "../../assets/image/logo.png"
import HeroImage from "../../assets/image/StickerLowDef.png"
import GithubLogo from "../../assets/image/github.png"
import InstagramLogo from "../../assets/image/instagram.png"
import LinkedinLogo from "../../assets/image/linkedin.png"
import GmailLogo from "../../assets/image/gmail.png"
import Text from "../../assets/image/tex.png"

import { PaintSplatterCanvas } from "../paintSplatter"
import SDButton from "../../assets/image/sdbutton.png"
import DSButton from "../../assets/image/dsbutton.png"
import ArtButton from "../../assets/image/artbutton.png"
import { SetStateAction, useEffect, useState } from "react"
import { Homepage } from "../../components/homepage"
import { Navbar } from "../../components/navbar"
import { Loading } from "../loading"
import { Software } from "../software"
import { Art } from "../art"

export const Hero = () => {    
    const [isLoading, setIsLoading] = useState(true);
    const [page, setPage] = useState("home");

    useEffect(() => {
        const imageUrls = [
            HeroImage,
            GithubLogo,
            InstagramLogo,
            LinkedinLogo,
            GmailLogo,
            SDButton,
            DSButton,
            ArtButton,
            Text, 
            Logo
        ];

        let loadedImages = 0;
        const totalImages = imageUrls.length;

        imageUrls.forEach(url => {
            const img = new Image();
            img.src = url;
            img.onload = () => {
                loadedImages++;
                if (loadedImages === totalImages) {
                    setIsLoading(false); // All images are loaded
                }
            };
        });
    }, []);

    const changePage = (newPage: SetStateAction<string>) => {
        setPage(newPage);
    };


    return (
        (isLoading ? (    
            <div className="flex justify-center items-center">
                <Loading size={200}/>
            </div> 
        ) : (
            <div> 
                <PaintSplatterCanvas>
                    <Navbar onPageChange={changePage}/>
                    {(page === "home" && <Homepage/>)}
                    {(page === "software" && <Software/>)}
                    {(page === "art" && <Art/>)}
                </PaintSplatterCanvas>
            </div>
        ))
        

    )
}
//"https://i.imgur.com/PNiveyq.png"
//"https://i.imgur.com/PZBJa9F.png"