import React, { FC, ReactNode, useEffect, useRef } from 'react';
import Background from "../../assets/image/canvas.png"

interface PaintSplatterCanvasProps {
    children?: ReactNode;
}

export const PaintSplatterCanvas: FC<PaintSplatterCanvasProps> = ({ children }) => {
    const canvasRef = useRef<HTMLCanvasElement>(null);
    // Changed to use a state for reactivity, but kept the useRef approach for simplicity
    const particles = useRef<any[]>([]);

    useEffect(() => {
        const canvas = canvasRef.current;
        if (!canvas) return;

        // Adjust canvas size to fill its parent
        canvas.width = canvas.offsetWidth;
        canvas.height = canvas.offsetHeight;

        const ctx = canvas.getContext('2d');
        if (!ctx) return;

        const resizeCanvas = () => {
            // Save the current drawing before resizing
            const imgData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    
            // Adjust canvas size
            canvas.width = window.innerWidth;
            canvas.height = window.innerHeight;
    
            // Scale and redraw the image data to fit the new canvas size
            ctx.putImageData(imgData, 0, 0);
        };
    
        window.addEventListener('resize', resizeCanvas);
        resizeCanvas(); // Initial resize to fit the screen

        const handleMouseDown = (e: MouseEvent) => {
            const rect = canvas.getBoundingClientRect();
            const x = e.clientX - rect.left;
            const y = e.clientY - rect.top;

            for (let i = 0; i < 36 * 2; i++) {
                particles.current.push({
                    x,
                    y,
                    angle: i * 10 * (Math.PI / 180), // Convert degrees to radians
                    size: 5 + Math.random() * 3,
                    life: 200000 + Math.random() * 50,
                    velocity: {
                        x: (Math.cos(i * 5 * Math.PI / 180) * 4) * Math.random() * 3,
                        y: (Math.sin(i * 5 * Math.PI / 180) * 4) * Math.random() * 3
                    },
                    gravity: 0.2
                });
            }
        }

        canvas.addEventListener('mousedown', handleMouseDown);

        let last = Date.now();
        const animate = () => {
            const now = Date.now();
            const delta = now - last;
            last = now;

            particles.current.forEach((p, i) => {
                p.x += Math.cos(p.angle) * 4;
                p.y += Math.sin(p.angle) * 4;
                p.life -= delta;
                p.size -= delta / 50;
                p.velocity.y += p.gravity;
                p.x += p.velocity.x;
                p.y += p.velocity.y;

            
                if (p.size <= 0 || p.life <= 0) {
                    particles.current.splice(i, 1);
                    return;
                } else {
                    ctx.beginPath();
                    ctx.arc(p.x, p.y, p.size, 0, Math.PI * 2, false);
                    ctx.fill();
                    const numberOfDroplets = 3 + Math.floor(Math.random() * 5); // Random number of droplets
                    for (let i = 0; i < numberOfDroplets; i++) {
                        const angle = Math.random() * Math.PI * 2; // Random angle
                        const radius = p.size * 10 + Math.random() * p.size * 10; // Radius of droplets
                        const dropletX = p.x + Math.cos(angle) * radius;
                        const dropletY = p.y + Math.sin(angle) * radius;
                        const dropletSize = Math.random() * p.size * 0.5; // Size of droplets

                        ctx.beginPath();
                        ctx.arc(dropletX, dropletY, dropletSize, 0, Math.PI * 2, false);
                        ctx.fill();
                    }

                    const numberOfBulbs = Math.max(1/(Math.abs(p.size - 0.03) * 20), 1) * 1; // Random number of droplets
                    for (let i = 0; i < numberOfBulbs; i++) {
                        const angle = Math.random() * Math.PI * 2; // Random angle
                        const radius = p.size * 0.5 + Math.random() * p.size * 0.5; // Radius of droplets
                        const dropletX = p.x + Math.cos(angle) * radius;
                        const dropletY = p.y + Math.sin(angle) * radius;
                        const dropletSize = Math.random() * p.size * 3; // Size of droplets

                        ctx.beginPath();
                        ctx.arc(dropletX, dropletY, dropletSize, 0, Math.PI * 2, false);
                        ctx.fill();
                    }
                }
            });

            requestAnimationFrame(animate);
        };

        requestAnimationFrame(animate);

        return () => {
            canvas.removeEventListener('mousedown', handleMouseDown);
        };
    }, []);

    return (
        <div className="relative w-full h-full">
            <canvas style={{ backgroundImage: `url(${Background})` }}  ref={canvasRef} className="bg-cover bg-center w-full h-full fixed top-0 left-0"></canvas>
            <div className="absolute top-0 left-0 w-full h-full">
                {children}
            </div>
        </div>
    );
};