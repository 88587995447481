import React from 'react';
import logo from './logo.svg';
import './App.css';
import {Hero} from './components/hero';
import { NextUIProvider } from '@nextui-org/react';
function App() {
  return (
    <NextUIProvider>
       <Hero/>
    </NextUIProvider>
  );
}

export default App;
