import {Card, CardHeader, CardBody, Input} from "@nextui-org/react";
import Check from "../../assets/image/Checkmark.png"
import { motion } from "framer-motion";
import { SetStateAction, useState } from "react";
export const Art = () => {
    const [password, setPassword] = useState('');
    const [isError, setIsError] = useState(false);

    const handlePasswordChange = (e: { target: { value: SetStateAction<string>; }; }) => {
        setPassword(e.target.value);
        setIsError(false); // Reset error state on input change
    };

    const verifyPassword = () => {
       setIsError(true);
    };

    const errorAnimation = {
        initial: { 
            boxShadow: '0px 0px 0px rgba(0, 0, 0, 0)',
            transition: { duration: 0.5 }
        },
        animate: { 
            boxShadow: '0px 0px 8px rgba(255, 0, 0, 1)',
        },
    };

    return (
        <div>

        <Card className="py-4" style={{
            backgroundColor: 'rgba(255, 255, 255, 0.6)', // Light theme
            border: 'none',
            maxWidth: '100%',
            boxShadow: 'var(--nextui-shadows-sm)', // Using Next UI's shadow variable
            borderRadius: '25px',
            marginLeft: '10%',
            marginRight: '10%',
            padding: '10px',
            minHeight: '40%',
            marginBottom: '10px'
        }}>
            <CardHeader className="pb-0 pt-2 px-4 flex-col items-start items-center justify-center  text-center">

                <h4 className="font-bold text-3xl font-sans">Portfolio is Protected</h4>
                <h3 className="font-bold text-2xl font-sans">You are currently in a guest area, if you have the password from my other portfolio please insert it here!</h3>
                {isError && (
                <p className="text-red-500 font-sans">Incorrect password, please try again.</p>
            )}
            </CardHeader>
            <CardBody className="overflow-visible items-center justify-center  text-center py-2">
            <motion.input

                variants={errorAnimation}
                initial = "initial"
                animate={isError ? "animate" : "initial"}
                transition={{duration: 0.5, repeat: Infinity, repeatType: 'reverse'}}
                type="password"
                name="portfolioPassword123" // Use a unique name
                onChange={handlePasswordChange}
                placeholder="Enter password"
                className={`max-w-xs ${isError ? 'border-red-500' : ''}`}
                style={{
                    backgroundColor: 'rgba(255, 255, 255, 1)',
                    borderRadius: '25px',
                    padding: '5px'
                }}
            />
               {!isError && <motion.img initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                transition={{ duration: 0.5, ease: "backOut" }}
                onClick={verifyPassword}
                whileHover={{
                    scale: 1.1,
                    rotate: 5,
                    transition: { duration: 0.3 },
                }}
                className="mt-5 object-contain h-96 min-w-[500px] w-[4rem] h-[4rem] pointer-events-auto" src={Check} alt="DS Button"/> }

            </CardBody>
        </Card>

        </div>
    )
}