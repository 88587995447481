import {Card, CardHeader, CardBody, CardFooter, Divider, Link, Image} from "@nextui-org/react";
import Lutece from "../../assets/image/lutece.png"
import { ProjectCard } from "../card-project";
import JHU from "../../assets/image/jhu.png"
import DSN from "../../assets/image/DSN.png"
import CPR from "../../assets/image/CPR.jpg"
import VT from "../../assets/image/VT.png"
import WC from "../../assets/image/wc.jpg"
import CADIS from "../../assets/image/cadis.jpg"
import VECTECH from "../../assets/image/VET.png"

/**
    logo: string;
    title: string;
    website: string;
    description: string;
    skills: string;
    hyperlink: string;
    hyperlinkText: string;
 */

import { motion } from 'framer-motion'
export const Software = () => {
   return (
     <div className ="p-5">
            <ProjectCard
            logo={VECTECH}
            title="Vectech Computer Vision Engineering Intern"
            website="https://vectech.io"
            skills="Python, Pytorch, Scikit-Learn, ELM, Transformers, Mutual Entropy, XCeption, CNN, RANSAC Manipulation, GAN"
            hyperlink=""
            hyperlinkText=""
            > 
                <p className='font-sans'>
                    Worked on creating a classification ELM model of 3D point clouds to quantify the color of an image into latent spatial features of 16 different quadrants of a mosquito imag 
                    from entropy, optical flow, the minkowski-bouligand dimensions, the first order statistics, and the correlelogram, used to increase the accuracy with a late fusion
                    method on the original XCeption model. 
                </p>
            </ProjectCard>
            <div className="py-2"></div>
            <ProjectCard
            logo={CADIS}
            title="CADIS Competition Entry"
            website="https://cataracts.grand-challenge.org/CaDIS/"
            skills="Python, Pytorch, DeepLab, ResNet50, MobileNetV3, UNet, Segformer, Keypoint Extraction"
            hyperlink="https://drive.google.com/file/d/1WnMw95TKns-0dZliDMnM-frgEOpuK0Mf/view?usp=sharing"
            hyperlinkText="View Paper"
            > 
                <p className='font-sans'>
                    Working on an tool segmentation and motion tracking for complicated Cataract Surgery, to prove that we can accurately detect the type of tool and the optical flow using the Lucas Kanade method and different computer vision tricks. 
                    Further work includes includes representing the optical flow as a 3D point cloud, and train another model to classify what novel and expert surgeons are doing differently, providing accurate and useful information on where to improve.
                </p>
            </ProjectCard>
            <div className="py-2"></div>
           <ProjectCard
            logo={WC}
            title="WISE Cities Fullstack Developer"
            website="https://wisecities.us/"
            skills="Typescript, Golang. NestJS, NextJS, Accessibility Research"
            hyperlink=""
            hyperlinkText=""
            > 
                <p className='font-sans'>
                    Working with a 2023 Terp Startup Winner adn 2023 Pitch Dingman Finalist company in order to create an application that makes communities accessible for an aging population. Work specifically
                    on communicating accessibility features across all sides of the software development cycle.
                </p>
            </ProjectCard>
            <div className="py-2"></div>
          <ProjectCard
            logo={VT}
            title="Hume IC CAE Data Scraping Tool Developer"
            website="https://hume.vt.edu//"
            skills="Python, Selenium, Flask, Beautiful Soup, Postgres, Scikit-Learn"
            hyperlink=""
            hyperlinkText=""
            > 
                <p className='font-sans'>
                    Worked with a professor from Virginia Tech in order to create a data scraping tool for articles from the New York Times, pulling about 10 years worth of articles
                    that mentioned immigrants, migrants, refugees, or asylum seekers to work with the analysis system NVivo.
                </p>
            </ProjectCard>
            <div className="py-2"></div>
            <ProjectCard
            logo={DSN}
            title="Data Science Network Software Engineering Internship"
            website="https://www.datasciencenigeria.org/"
            skills=".NET Framework, .NET Core, ASP.NET MVC ASP.NET Web API, SignalR, Blazor, Azure Functions, SQL Database, Python, Machine Learning, Scikit-Learn, NLP, Pytorch "
            hyperlink="https://www.datasciencenigeria.org/"
            hyperlinkText="View Website"
            > 
                <p className='font-sans'>
                Project aims to work with education technology, allowing me to get antiquated with a whole lot of the software development cycle from project planning, solution proposals, market analysis,
                and the AGILE software development on a variety of automated bots and parts of an upcoming interactive learning tools using WPF and machine learning for adaptive testing based on learner's 
                performance. 

                </p>
            </ProjectCard>
            <div className="py-2"></div>
            <ProjectCard
            logo={JHU}
            title="JHU Sentience"
            website="https://jhu-courses.herokuapp.com"
            skills="Javascript, Typescript, React, Node.js, Express, MongoDB, NLP, Bert Architecture, Golang, "
            hyperlink="https://jhu-courses.herokuapp.com"
            hyperlinkText="View Development Platform"
            > 
                <p className='font-sans'>
                      Project aims to create a web application that allows students to touch base on classes using grafted sentiment analysis modeling from one comment block form reviews, giving an outlook that students can use to help their own understandings of the course at large and give them just the right amount of information to hopefully not confuse them even further!
                </p>
            </ProjectCard>
            <div className="py-2"></div>
            <ProjectCard
            logo={Lutece}
            title="JWT Plugin"
            website="https://lutece.paris.fr/lutece/"
            skills="Java, Javascript, Netbeans, SQL, AJAX, CSS, HTML, Spring, Maven Lifecycle, Git"
            hyperlink="https://dev.lutece.paris.fr/plugins/library-jwt/project-reports.html"
            hyperlinkText="View Maven Logs"
            > 
                <p className='font-sans'>
                Give Lutece website that ability to write, read, and verify the signature of JSON Web Tokens. For the purposes of security when it comes to an easier way to store cookies and sensitive payloads nd it's app like modularity allows it to be almost plug in play in stature.
                </p>
            </ProjectCard>
            <div className="py-2"></div>
            <ProjectCard
            logo={CPR}
            title="CPR Teacher Augmented Reality Unity Project"
            website="https://www.datasciencenigeria.org/"
            skills="Unity, C#, Vuforia, Mixed Reality Toolkit "
            hyperlink="https://github.com/necrosin/LifeSaverCPRAED"
            hyperlinkText="View on Github"
            > 
                <p className='font-sans'>
                Project aims to work with education technology, allowing me to get antiquated with a whole lot of the software development cycle from project planning, solution proposals, market analysis,
                and the AGILE software development on a variety of automated bots and parts of an upcoming interactive learning tools using WPF and machine learning for adaptive testing based on learner's 
                performance. 

                </p>
            </ProjectCard>
            <div className="py-2"></div>
            <ProjectCard
            logo={Lutece}
            title="Profanity Filter Plugin"
            website="https://lutece.paris.fr/lutece/"
            skills="Java, Javascript, Netbeans, SQL, AJAX, CSS, HTML, Spring, Maven Lifecycle, Git"
            hyperlink="https://github.com/lutece-platform/lutece-tech-plugin-profanity-filter"
            hyperlinkText="View on Github"
            >
                <p className='font-sans'>
                Created a Profanity Filter Plugin designed as an add-on for the Lutece framework to automatically detect and take action on user-generated content containing profanity. The aim was to maintain a clean and respectful environment on websites powered by Lutece, enhancing user experience and complying with content policies, and it's app like modularity allows it to be almost plug in play in stature.
                </p>
            </ProjectCard>
     </div>

     
   )
}
//"Created a Profanity Filter Plugin designed as an add-on for the Lutece framework to automatically detect and take action on user-generated content containing profanity. The aim was to maintain a clean and respectful environment on websites powered by Lutece, enhancing user experience and complying with content policies, and it's app like modularity allows it to be 
//almost plug in play in stature."
//"https://github.com/lutece-platform/lutece-tech-library-jwt"